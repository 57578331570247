<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="filteredItems"
          :fields="getAllFields"
          current-page="1"
          per-page="0"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onEditUserPermissions(row.item)"
                class="mt-1 mr-1"
                title="
                    Modifica Permessi Utente
                  "
              >
                <b-icon icon="person-fill"></b-icon>
              </b-button>
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onEditBrokersPermissions(row.item)"
                class="mt-1 mr-1"
                title="
                    Modifica Permessi Produttori
                  "
              >
                <b-icon icon="people-fill"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('lisa_for_apps')"
                size="sm"
                variant="lisaweb"
                @click="onEditLisaForAppsPermissions(row.item)"
                class="mt-1 mr-1"
                title="
                    Modifica Permessi LisaForApp
                  "
              >
                <b-icon icon="person-square"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('reports')"
                size="sm"
                variant="lisaweb"
                @click="onEditReportsPermissions(row.item)"
                class="mt-1 mr-1"
                title="
                    Modifica Permessi Report
                  "
              >
                <b-icon icon="file-text"></b-icon>
              </b-button>
            </div>
          </template>

          <template #cell(role)="{ item }">
            {{ item.roles ? item.roles.find((role) => role).name : "" }}
          </template>
        </b-table>
      </div>
      <!-- <b-row style="display: none">
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
      </b-row> -->
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";

export default {
  name: "UsersTable",
  extends: template,
  props: {
    filteredItems: Array,
  },
  data() {
    return {
      args: null,
    };
  },
  components: {
    BaseIcon,
  },
  methods: {
    onEditUserPermissions(user) {
      this.$emit("user_permissions", user);
    },
    onEditBrokersPermissions(user) {
      this.$emit("brokers_permissions", user);
    },
    onEditLisaForAppsPermissions(user) {
      this.$emit("lisa_for_apps_permissions", user);
    },
    onEditReportsPermissions(user) {
      this.$emit("reports_permissions", user);
    },
    usersMapping(data) {
      let adminRoles = [
        "admin-agenzia",
        "super-admin",
        "admin-assistenza",
        "servizio",
      ];
      let users = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].roles.length; j++) {
          if (!adminRoles.includes(data[i].roles[j].name)) {
            users.push(data[i]);
          }
        }
      }
      return users;
    },
  },
  beforeMount() {
    this.args = { items: { handle: this.usersMapping } };
  },
};
</script>
