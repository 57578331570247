<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <!-- <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col> -->
      <b-col align="right">
        <b-button
          v-if="!isLoadingCount && canVerb(resource, 'store')"
          :id="`popover-create`"
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <!-- <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="username"
                type="text"
                label="Nome utente"
                v-model="filter.byAttribute.username"
                placeholder="Inserisci un nome utente"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="title"
                type="text"
                label="Email"
                v-model="filter.byAttribute.email"
                placeholder="Inserisci una email"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse> -->
    <div class="mt-2">
      <div>
        <b-overlay center :show="isLoading" rounded="sm">
          <users
            @edit="onEdit"
            :fields="fields"
            :repository="repository"
            :resource="resource"
            :filterName="filterName"
            :filterOn="{
              byRelations: ['byRole'],
            }"
            :filteredItems="filteredItems"
            @destroy="onDestroy"
            @user_permissions="onUpdateUserPermissions"
            @brokers_permissions="onUpdateBrokersPermissions"
            @lisa_for_apps_permissions="onUpdateLisaForAppsPermissions"
            @reports_permissions="onUpdateReportsPermissions"
            :ref="tableRef"
            :onlyActions="['destroy', 'edit', 'lisa_for_apps', 'reports']"
          ></users>
          <template #overlay>
            <div class="text-center mt-5">
              <base-icon name="loading" width="35" height="35" />
              <p id="cancel-label">Operazione in corso...</p>
            </div>
          </template>
        </b-overlay>
      </div>
    </div>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import Users from "@/components/tables/Users";
// import BaseInput from "@/components/form/BaseInput";
import { mapActions } from "vuex";
// import { canModule } from "@/utils/permissions";
import { isAdmin } from "@/utils/permissions";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      isLoading: false,
      repository: "user",
      resource: "users",
      tableRef: "UsersTableRef",
      filterName: "filterUsers",
      filter: this.initFilter(),
      user_count: null,
      args: {},
      isLoadingCount: true,
      filteredItems: [],
      fields: [
        {
          key: "username",
          label: this.getDictionary("username", "user"),
          // sortable: true,
        },
        {
          key: "email",
          label: this.getDictionary("email", "user"),
          // sortable: true,
        },
        {
          key: "role",
          label: this.getDictionary("role", "user"),
        },
      ],
    };
  },
  components: {
    Users,
    BaseIcon,
    // BaseInput,
  },
  mounted() {
    this.fetchCountUser();
  },
  methods: {
    // canModule,
    isAdmin,
    initFilter() {
      let init = {
        byAttribute: {
          email: null,
          username: null,
        },
      };
      return init;
    },
    fetchCountUser() {
      this.isLoading = true;
      this.isLoadingCount = true;
      const Repo = RepositoryFactory.get(this.repository);
      Repo.index("perPage=0&byRole")
        .then((response) => {
          this.filteredItems = response.data.data.filter((object) => {
            return (
              object.id === this.$store.state.auth.user.id ||
              (object.username.indexOf("operatore") < 0 &&
                object.username.indexOf("ws@") < 0 &&
                object.username.indexOf("admin.") < 0 &&
                !object.username.endsWith(".api") &&
                object.username !== "admin" &&
                object.username !== "lisalex")
            );
          });
          // this.user_count = this.filteredItems.filter(
          //   (user) => !this.isAdmin(user, ["servizio"])
          // ).length;
          this.user_count = this.filteredItems.length;
          this.isLoadingCount = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoadingCount = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}` },
      });
    },
    onAdd() {
      console.debug(
        `license: ${this.$store.state.auth.license}, count: ${this.user_count}`
      );
      if (this.$store.state.auth.license <= this.user_count) {
        this.$showSnackbar({
          preset: "error",
          text: `Limite Utenti Raggiunto`,
        });
      } else {
        this.$router.push({ name: `${this.resource}.create` });
      }
    },
    onUpdateUserPermissions(user) {
      this.$router.push({
        name: `${this.resource}.edit2`,
        params: { user: user },
      });
    },
    onUpdateLisaForAppsPermissions(user) {
      this.$router.push({
        name: `lisa_for_apps.index`,
        params: { user: user },
      });
    },
    onUpdateReportsPermissions(user) {
      this.$router.push({
        name: `reports.edit`,
        params: { user: user },
      });
    },
    onUpdateBrokersPermissions(user) {
      this.$router.push({
        name: `brokers.edit3`,
        params: { user: user },
      });
    },
    // isAdmin(user) {
    // let adminRoles = [
    //   "admin-agenzia",
    //   "super-admin",
    //   "admin-assistenza",
    //   "servizio",
    // ];
    //   return user.roles.find((role) => adminRoles.includes(role.name))
    //     ? true
    //     : false;
    // },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Utente Eliminato con successo",
          });
          this.fetchCountUser();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      // this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      // this.resetPagination();
      this.removeFilterByName(name);
      // this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    // ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>
